/*
 * @Author: yangQi
 * @LastEditors:  
 * @Date: 2021-10-19 12:06:21
 * @LastEditTime: 2021-10-27 17:50:49
 * @Description:  
 * @FilePath: /outside/src/page/FindPage/index.tsx
 */

import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { req_find_byName, req_find_byTime } from '../../js/req'
import '../../publichcs/bootstrap.min.css'
import { formatDate, judgeMonthNayNum, LOG } from '../../util'

const typeType=["","cellmate","pear flower","little devil","cellmate 2","SP base","key pods"]
export default function FindPage(props: any) {
    const history = useHistory()
    const [years, setYears] = useState<Array<string>>([])
    const [months, setMonths] = useState<Array<string>>([])

    const [enYears, setEndYears] = useState<Array<string>>([])
    const [endMonths, setEndMonths] = useState<Array<string>>([])


    const [startYear, setStartYear] = useState<string>('2021')
    const [startMonth, setStartMonth] = useState<string>('01')

    const [endyear, setEndYear] = useState<string>('2021')
    const [endmonth, setEndMonth] = useState<string>('01')
    const [toyname, setToyname] = useState<string>('')
    const [serialNumber, setSerialNumber] = useState<string>('')
    const [dataList, setDataList] = useState<Array<reqParam.res_find_detail>>([])

    const [pageNum, setPageNum] = useState<number>(1)
    const [totalNum, setTotalNum] = useState<number>(21)
    const [nextPageNum, setNextPageNum] = useState<number>(1)
    const [isNoData, setIsNoData] = useState<boolean>(false)

    useEffect(() => {
        setYears(['2021'])
        setMonths(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'])
        setEndYears(['2021'])
        setEndMonths(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'])
        checkToken()
        // eslint-disable-next-line
    }, [])

    const checkToken = () => {
        let token = localStorage.getItem('token')
        if (!token) {
            history.push('/login')
        }
    }

    const req_find = (pageIndex: 0 | 1 | 2 | 3) => {
        const findPageArr = [1, pageNum - 1, nextPageNum, parseInt(`${totalNum / 20}`) + 1]
        if ((!!toyname || toyname === '0') || (!!serialNumber || serialNumber === '0')) {
            LOG(toyname, serialNumber)
            const data = {
                toyName: toyname,
                serialNumber: serialNumber,
                pageSize: 20,
                pageNum: findPageArr[pageIndex]
            }
            LOG(data)
            return req_find_event1_name(data)
        }
        const dayss = judgeMonthNayNum(endyear, endmonth)
        const data: reqParam.req_find_month = {
            beginDate: startYear + startMonth + '01',
            endDate: endyear + endmonth + dayss,
            pageSize: 20,
            pageNum: findPageArr[pageIndex]
        }
        LOG('giao', data)
        return req_find_event_month(data)
    }

    const req_find_event1_name = (data: reqParam.req_find_name) => {
        return req_find_byName(data).then((res) => {
            if (!res || !res.list) {
                toast.warn("there is no data in this search");
                setIsNoData(true)
                return
            }
            setIsNoData(false)
            setDataList(res.list)
            !!res.pageNum && setPageNum(res.pageNum)
            !!res.nextPage && setNextPageNum(res.nextPage)
            !!res.total && setTotalNum(res.total)
        }).catch((e) => {
            LOG(e)

        })
    }
    const req_find_event_month = (data: reqParam.req_find_month) => {
        return req_find_byTime(data).then((res) => {
            if (!res || !res.list) {
                toast.warn("there is no data in this search");
                setIsNoData(true)
                return
            }
            setIsNoData(false)
            setDataList(res?.list)
            !!res.pageNum && setPageNum(res.pageNum)
            !!res.nextPage && setNextPageNum(res.nextPage)
            !!res.total && setTotalNum(res.total)
        }).catch((e) => {
            LOG(e)
        })
    }

    const seleteStartMonth = (monthNum: string) => {
        let startMonths = Number(monthNum)
        let endMonthsNum: Array<string> = []
        for (let i = startMonths; i <= 12; i++) {
            if (i < 10) {
                endMonthsNum.push(`0${i}`)
            } else {
                endMonthsNum.push(`${i}`)
            }
        }
        setStartMonth(monthNum)
        setEndMonths(endMonthsNum)
    }

    const loginOut = () => {
        localStorage.removeItem("token")
        history.push('/login')
    }

    return (

        <div className="container">
            <div className="row mb-2">
                <div className="col-md-2 ">toyName:</div>
                <input className="col-md-3" value={toyname} onChange={event => setToyname(event.target.value)} />
            </div>
            <div className="row mb-2">
                <div className="col-2 ">serialNumber:</div>
                <input className="col-sm-3" value={serialNumber} onChange={event => setSerialNumber(event.target.value)} />
            </div>
            <button className="btn btn-danger col-1 " style={{
                position: 'absolute',
                zIndex: 3,
                top: '2%',
                right: '3%'
            }} onClick={loginOut}>login out</button>
            <div className="row">
                <div className="col-5 row border">
                    <div className="me-3">
                        start Time:
                    </div>
                    <select className="form-select mb-2 me-5" style={{
                        width: "40.6666%"
                    }} value={startYear} onChange={event => setStartYear(event.target.value)} aria-label="Default select example">
                        {years.map((item, index) => {
                            return <option key={item} value={item}>{item}</option>
                        })}
                    </select>

                    <select className="form-select mb-2" style={{
                        width: "40.6666%"
                    }} value={startMonth} onChange={event => seleteStartMonth(event.target.value)} aria-label="Default select example">
                        {months.map((item, index) => {
                            return <option key={item} value={item}>{item}</option>
                        })}
                    </select>
                </div>
                <div className="col-5 row border offset-1">
                    <div className="me-3">
                        end Time:
                    </div>
                    <select className="form-select mb-2 me-5" style={{
                        width: "40.6666%"
                    }} value={endyear} onChange={event => setEndYear(event.target.value)} aria-label="Default select example">
                        {enYears.map((item, index) => {
                            return <option key={item} value={item}>{item}</option>
                        })}
                    </select>

                    <select className="form-select mb-2" style={{
                        width: "40.6666%"
                    }} value={endmonth} onChange={event => setEndMonth(event.target.value)} aria-label="Default select example">
                        {endMonths.map((item, index) => {
                            return <option key={item} value={item}>{item}</option>
                        })}
                    </select>
                </div>
            </div>
            <div>
                <button className="btn btn-dark col-3 mb-2 mt-2" onClick={() => req_find(0)}>search</button>
            </div>
            {isNoData? <div >there is no data in this search</div>:<div>  there are <b style={{ color: 'red' }}>{dataList?.length}</b>  records in this page (total = {totalNum}) </div>}

            <div><button style={{ textDecoration: 'none', border: 'none', backgroundColor: "white", color: 'blue' }} onClick={() => req_find(0)}>1 </button>&nbsp;&nbsp;&nbsp;<button style={{ textDecoration: 'none', border: 'none', backgroundColor: "white", color: 'red' }} onClick={() => req_find(1)} >&lt;</button>&nbsp;&nbsp;&nbsp;{pageNum}&nbsp;&nbsp;&nbsp;<button style={{ textDecoration: 'none', border: 'none', backgroundColor: "white", color: 'red' }} onClick={() => req_find(2)}>&gt;</button>&nbsp;&nbsp;&nbsp;<button style={{ textDecoration: 'none', border: 'none', backgroundColor: "white", color: 'blue' }} onClick={() => req_find(3)} > {totalNum % 20 === 0 ? totalNum / 20 : parseInt(`${totalNum / 20 + 1}`)}</button> </div>
            < table className="table table-striped " >

                <thead>
                    <tr >
                        <th scope="col-2">#</th>
                        <th scope="col-3">toyName</th>
                        <th scope="col-3">takeinTime</th>
                        <th scope="col-3">serialNumber</th>
                        <th scope="col-3">typeID</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataList.map((item, index) => {
                            return (
                                <tr key={item.serialNumber}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.toyName}</td>
                                    <td>{formatDate(item.createTime)}</td>
                                    <td>{item.serialNumber}</td>
                                    <td>{typeType[item.typeId]}</td>
                                </tr>
                            )
                        })
                    }


                </tbody>
            </table >


        </div >
    )

}