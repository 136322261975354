/*
 * @Author: yangQi
 * @LastEditors:  
 * @Date: 2021-10-19 09:51:09
 * @LastEditTime: 2021-10-23 15:20:26
 * @Description:  
 * @FilePath: /outside/src/App.tsx
 */

import LoginBox from './component/LoginBox';
import './publichcs/bootstrap.min.css'
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import FindPage from './page/FindPage';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [token, setToken] = useState<string>('')

  useEffect(() => {
    if (!token) {
      const tokens = localStorage.getItem('token')
      if (!!tokens) {
        setToken(tokens)
      }
    }
    // eslint-disable-next-line
  }, [])
  return (
    <HashRouter >
      <img src="backgroundIMG.jpeg" alt="" width="100%" height="100%" style={{
        position: 'absolute',
        zIndex: -3,
      }} />
      <header className="App-header">
      </header>
      <Switch>
        <Route path="/login" component={LoginBox} />
        <Route path='/search' component={FindPage} />
        {!!token ? <Redirect to="/search"></Redirect> : <Redirect to="/search"></Redirect>}
      </Switch>
      <ToastContainer />
    </HashRouter>
  )
}

export default App;
