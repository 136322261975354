/*
 * @Author: yangQi
 * @LastEditors:  
 * @Date: 2021-10-19 10:57:47
 * @LastEditTime: 2021-10-25 15:31:00
 * @Description:  
 * @FilePath: /outside/src/component/LoginBox/index.tsx
 */
import React, { useEffect, useState } from 'react';
import '../../App.css';
import '../../publichcs/bootstrap.min.css';
import { reqLogin, updateCode, judgeCode, getSmsCode } from '../../js/req';
import { useHistory } from 'react-router-dom';

export default function LoginBox(props: any) {

  const [US, setUS] = useState('');
  const [smscode, setSmscode] = useState('');
  const [code, setCode] = useState('');
  const [codeUrl, setCodeUrl] = useState('')
  useEffect(() => {
    updateCode(setCodeUrl)
  }, [])
  const history = useHistory()
  const jumpSearch = () => {
    history.push('/search')
  }

  return (
    <div className="App" >
    
      QIUI product search
      <div className="container mt-3"  >
        <div className="row mb-3 offset-md-1">
          <div className="col-2 border  " >
            EU-US
          </div>
          <input className="col-5 ms-2" onChange={event => setUS(event.target.value)} ></input>

        </div>

        <div className="row mb-3 offset-md-1">
          {/* <div className="col-2"></div> */}
          <input className="col-2  border-red border-1 " onChange={event => setCode(event.target.value)} ></input>
          <img src={codeUrl} onClick={() => updateCode(setCodeUrl)} alt="" style={{
            width: "6em",
            height: "2em"
          }} />
          <button className="btn btn-primary col-2 " onClick={() => judgeCode({ code, US }).then(() => getSmsCode(US))}>get</button>
        </div>

        <div className="row  offset-md-1">
          <div className="col-2 border ">
            code
          </div>
          <input className="col-5 border-1 ms-2" onChange={event => setSmscode(event.target.value)} ></input>
        </div>
        <button className="btn btn-primary me-5 mt-3 " onClick={() => {
          reqLogin({ US, code, smsCode: smscode ,jumpSearch})
        }}>login</button>
      </div>
    </div>
  );
}
