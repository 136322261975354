/*
 * @Author: yangQi
 * @LastEditors:  
 * @Date: 2021-10-19 16:00:16
 * @LastEditTime: 2021-10-22 20:09:02
 * @Description:  
 * @FilePath: /outside/src/util.ts
 */
export function formatDate(timeStamp: number) {
    const now = new Date(timeStamp)
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var date = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();
    return year + "/" + month + "/" + date + "-" + hour + ":" + minute + ":" + second;
}
export const LOG =(...data:any[])=>{}
// export const LOG = console.log.bind(this)

export const judgeMonthNayNum = (years: string, month: string) => {
    
    switch (month) {
        case '01':
            return 31
        case '02':
            if (Number(years) / 4 === 0) {
                return 29
            }
            return 28
        case '03':
            return 31

        case '04':
            return 30

        case '05':
            return 31

        case '06':
            return 30

        case '07':
            return 31

        case '08':
            return 31

        case '09':
            return 30

        case '10':
            return 31

        case '11':
            return 30

        case '12':
            return 31


    }

}