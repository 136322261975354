/*
 * @Author: yangQi
 * @LastEditors:  
 * @Date: 2021-06-03 11:35:09
 * @LastEditTime: 2021-10-23 11:02:16
 * @Description:  
 * @FilePath: /outside/src/net/crypto.ts
 */
import CryptoJS from 'crypto-js'
import aes from 'crypto-js/aes'
const Base64 = require('./base64')
const base = new Base64();

let key1 = CryptoJS.enc.Hex.parse('4c4b535e2a26574a32334f646c2f356b')
const keyList = [key1]


export const decode = <S>(baseAfter: string): Promise<reqParam.res_results<S>> => {
    return new Promise((resolve, reject) => {
        try {
            const baseBefor = base.decode(baseAfter)
            normalDecrypt(baseBefor, 0)
                .then((aesBefor) => {
                    const message: reqParam.res_results<S> = JSON.parse(aesBefor)
                    resolve(message)
                })
                .catch((e) => {
                    reject({ 'decode ERR': e })
                })
        }
        catch (e) {
            reject({ 'decode ERR': e })
        }
    })
}
export const encode = (message: object | string | []): Promise<string> => {
    return new Promise((resolve, reject) => {
        try {
            // const aesBefor = message
            const aesBefor = JSON.stringify(message)
            // console.log('aesBefor',aesBefor)
            normalEncrypt(aesBefor, 0)
                .then((baseBefor) => {
                    const baseAfter = base.encode(baseBefor)
                    resolve(baseAfter)
                })
                .catch((e) => {
                    reject({ 'encode ERR': e })
                })
        } catch (e) {
            reject({ 'encode ERR': e })
        }
    })
}


const normalEncrypt = async (message: object | string | [], type: number): Promise<string> => {
    let MSG = typeof (message) !== 'string' ? JSON.stringify(message) : message
    const enc = await aes.encrypt(MSG, keyList[type], {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })

    return enc.ciphertext.toString()
}
const normalDecrypt = async (message: string, type: number): Promise<string> => {
    let MSG = message.toString()
    const dec = await aes.decrypt(CryptoJS.format.Hex.parse(MSG), keyList[type], {
        //iv:iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return CryptoJS.enc.Utf8.stringify(dec)
}