/*
 * @Author: yangQi
 * @LastEditors:  
 * @Date: 2021-10-19 12:29:28
 * @LastEditTime: 2021-10-23 15:09:49
 * @Description:  
 * @FilePath: /outside/src/js/req.ts
 */
import { toast } from 'react-toastify'
import { req_Normal_Post } from '../net/net'

import URL from '../net/url.json'
import { LOG } from '../util'
const codePath = '/user/getVerificationCode?'
export function reqLogin(props: reqParam.req_login): void {
    const data = {
        "loginName": props.US,
        "smsCode": props.smsCode,
        "verificationCode": props.code
    }
    req_Normal_Post<reqParam.res_login>('/user/login', data)
        .then((res) => {
            if (!res) {
                return
            }
            localStorage.setItem('token', res)
            props.jumpSearch()
        }).catch((e) => {
        })
}
export function updateCode(setCode: React.Dispatch<React.SetStateAction<string>>): void {
    let date = new Date().getTime()
    let codepaths = URL.baseUrl + codePath + date
    setCode(codepaths)
}

export function judgeCode(props: reqParam.req_checkcode): Promise<boolean> {
    return new Promise((resolve, reject) => {
        LOG('Jge code', props.code)

        req_Normal_Post<reqParam.res_login>('/user/validatedVerificationCode', { verificationCode: props.code, loginName: props.US }).then((res) => {
            resolve(true)
            LOG('jugde code res', res)
        }).catch((e) => {
            reject(false)
            LOG('jugde code false', e)

        })
    })

}

export function getSmsCode(US: string): void {
    LOG('get sms code')

    req_Normal_Post<reqParam.res_login>('/sms/sendSmsCode', {
        loginName: US
    }).then((res) => {
        LOG('get sms code success')
        toast.success('get TEL code success')
    }).catch((e) => {

    })

}

export function req_find_byName(data: reqParam.req_find_name) {
    return req_Normal_Post<reqParam.res_find_result>('/toyInventory/searchToys', data)
}
export function req_find_byTime(data: reqParam.req_find_month) {
    return req_Normal_Post<reqParam.res_find_result>('/toyInventory/queryToysByMonth', data)
}
