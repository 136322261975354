/*
 * @Author: yangQi
 * @LastEditors:  
 * @Date: 2021-06-03 11:17:19
 * @LastEditTime: 2021-10-23 15:16:59
 * @Description:  
 * @FilePath: /outside/src/net/net.ts
 */
import { LOG } from '../util'
import { decode, encode } from './crypto'
import { baseUrl } from './url.json'
import { toast } from 'react-toastify'
const urlHead = baseUrl

export function req_Normal_Post<T>(urlPath: string, data: object | [] | string): Promise<T | null> {
    return new Promise(async (resolve, reject) => {
        const loadingID = toast.loading('loading...')
        let reqData = await encode(data)
        LOG('===hex===', data, reqData)
        let token = localStorage.getItem('token')
        token = !!token ? token : ''
        fetch(urlHead + urlPath, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: !!token ? token : ''
            },
            // body: JSON.stringify(data)
            body: reqData
        }).then((res) => {
            return res.text()
        })
            .then((res) => {
                return decode<T>(res)
            })
            .then((res) => {
                toast.update(loadingID, { type: 'success', isLoading: false,autoClose:750 })
                if (res?.state !== 'success') {
                    res.message && toast.error(res.message)
                    return reject({ err: 'error', data: res?.message })
                }
                if (!!res.data) {
                    return resolve(res?.data)
                }
                resolve(null)
            })
            .catch((e) => {
                toast.update(loadingID, { type: 'error', isLoading: false,autoClose:3500 })
                reject({ err: 'netword error:', data: null })
                toast.error('netword error')
            })
    })
}


export function req_Normal_Get<T>(urlPath: string, data: object | [] | string): Promise<T> {
    let token = localStorage.getItem('token')

    return new Promise(async (resolve, reject) => {
        let reqData = await encode(data)
        token = !!token ? token : ''
        fetch(urlHead + urlPath + '?' + reqData, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: token ? token : ''
            },
        }).then((res) => {
            return res.text()
        }).then((res) => {
            return decode<T>(res)
        }).then((res) => {
            if (res?.state !== 'success') {
                res.message && toast.error(res.message)
                return reject({ err: 'error', data: res?.message })
            }
            if (!!res.data) {
                resolve(res?.data)
            }
        }).catch((e) => {
            reject({ err: e })
            toast.error('netword error')
        })

    })


}


